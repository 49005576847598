






































import Vue from "vue";

export default Vue.extend({
  name: "MallSoldOut",
  data: () => {
    return {
      canAdmin: false,
      objects: [],
      showError: false,
      error: '',
      success: '',
      showSuccess: false,
      loaded: false,
    };
  },
  methods: {
    async isMallStaff() {
      try {
        await this.$http.get(
          `/mall/can_admin`,
        );
        this.canAdmin = true;
      } catch (e) {
        console.log(e);
      }
    },
    async getResults(): Promise<void> {
      this.objects = [];
      try {
        const response = await this.$http.get('/mall/soldout');
        response.data.objects.objects.forEach((obj) => {
          if(obj.instances === obj.quantity && (obj.limit === obj.quantity || ['0', 'Unlimited', null].includes(obj.limit))){
            this.objects.push(obj);
          }
        });
        this.showSuccess = true;
      } catch (errorResponse: any) {
        if (errorResponse.response.data.error) {
          this.error = errorResponse.response.data.error;
          this.showError = true;
        } else {
          this.error = "An unknown error occurred";
          this.showError = true;
        }
      }
    },
    async remove(objectId): Promise<void> {
      this.showSuccess = false;
      this.showError = false;
      try {
        this.error = '';
        this.showError = false;
        await this.$http.post("/mall/remove", {
        'objectId': objectId,
        });
        this.success = 'Object removed from store.';
        this.showSuccess = true;
        this.getResults();
      } catch (errorResponse: any) {
        if (errorResponse.response.data.error) {
          this.error = errorResponse.response.data.error;
          this.showError = true;
        } else {
          this.error = "An unknown error occurred";
          this.showError = true;
        }
      }
    },
  },
	async mounted(): Promise<void> {
    this.loaded = true;
    this.isMallStaff();
    this.getResults();  
  },
});
