












































import Vue from "vue";

export default Vue.extend({
  name: "WorldBrowserTools",
  data: () => {
    return {
      adminCheck: false,
      loaded: false,
      canAdmin: false,
      data: null,
      mallId: null,
    };
  },
  methods: {
    async getMallId(){
      this.mallId = await this.$http.get('/place/mall')
    },
    async checkAdmin() {
      try {
        this.adminCheck = await this.$http.get(
          `/colony/${this.$store.data.place.id}/can_admin`);
        this.canAdmin = true;
      } catch (error) {
        this.canAdmin = false;
      }
    },
    async opener(link) {
      window.open(link, "targetWindow", "height=650,width=800,menubar=no,status=no");
    },
  },
  mounted() {
    this.checkAdmin();
    this.getMallId();
  },
  watch: {
    async $route(to, from) {
      console.log("Place Change");
      await this.checkAdmin();
      this.loaded = true;
    },
  },
});
