





























































































import Vue from "vue";
import Modal from './Modal.vue';
import ModalMixin from './mixins/ModalMixin';
import AvatarModal from "./AvatarModal.vue";
import ModalService from "./services/ModalService.vue";

export default Vue.extend({
  name: "AvatarUploadModal",
  components: { Modal },
  data: () => {
    return {
      showError: false,
      showSuccess: false,
      showForm: true,
      error: "",
      name: '',
      gestures: '',
      wrlFile: {},
      imageFile: {},
      textureFile: {},
      avatarPrivate: '',
      avatarPrivateOptions: [
        {
          value: '',
          label: 'Select One',
        },
        {
          value: 0,
          label: 'Anyone',
        },
        {
          value: 1,
          label: 'Only Me',
        }
      ],
    };
  },
  methods: {
    back(): void {
      ModalService.open(AvatarModal);
    },
    setFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      this[e.target.dataset.id] = files[0];
    },
    async upload(): Promise<void> {
      this.showError = false;
      this.showSuccess = false;
      try {
        await this.$http.post("/avatar/upload", {
          name: this.name,
          wrlFile: this.wrlFile,
          textureFile: this.textureFile,
          imageFile: this.imageFile,
          gestures: this.gestures,
          private: this.avatarPrivate
        }, true);
        this.showSuccess = true;
        this.showForm = false;
      } catch (errorResponse: any) {
        if (errorResponse.response.data.error) {
          this.error = errorResponse.response.data.error;
          this.showError = true;
        } else {
          this.error = "An unknown error occurred";
          this.showError = true;
        }
      }
    }
  },
  mounted() {
  },
  mixins: [ModalMixin],
});
