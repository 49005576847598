



























































































































































import Vue from 'vue';

export default Vue.extend({
  name: "LoginPage",
  data: () => {
    return {
      username: "",
      password: "",
      showError: false,
      error: "",
    };
  },
  methods: {
    async login(): Promise<void> {
      this.showError = false;
      try {
        const { data } = await this.$http.post("/member/login", {
          username: this.username,
          password: this.password,
        });

        this.$store.methods.setUser({
          username: data.username,
          hasHome: data.hasHome,
        });

        this.$store.methods.setToken(data.token);
        const { redirect } = this.$route.query;
        // redirect can be a string or an array of strings so we have to handle both
        const redirectString = typeof redirect === "object"
          ? redirect.join('/')
          : redirect;
        const path: string = redirectString || "/place/enter";
        this.$router.push({ path });
      } catch (error) {
        if(error.response.data.error === "banned") {
          this.$router.push({ name: "banned" });
        }
        else if (error.response.data.error) {
          this.error = error.response.data.error;
          this.showError = true;
        } else {
          this.error = "An unknown error occurred";
          this.showError = true;
        }
      }
    },
  },
});
