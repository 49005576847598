


































































































import Vue from "vue";

export default Vue.extend({
  name: "ObjectProperties",
  data: () => {
    return {
      sessionId: null,
      memberId: null,
      memberUsername: null,
      ownerId: null,
      objectId: null,
      placeId: null,
      imgFile: null,
      objectFile: null,
      originalName: "",
      name: "",
      error: "",
      showError: false,
      success: "",
      walletBalance: null,
      canModify: false,
      quantity: null,
      price: null,
      buyer: null,
      instances: 0,
      active: "properties",
      mallObject: false,
    };
  },
methods: {
  async objectProperties(): Promise<void>{
    if(this.$route.name === 'mall-object-properties'){
      this.mallObject = true;
    }
    this.objectId = this.$route.params.object_id;
    const info = await this.$http.get(`/member/info/${this.$store.data.user.id}`);
    this.walletBalance = info.data.memberInfo.walletBalance;
    if(this.mallObject){      
      await this.$http.get(`/mall/object/${ this.objectId }`)
        .then((response) => {
          let object = response.data.object[0];
          this.imgFile = `/assets/object/${object.directory}/${object.image}`;
          this.objectFile = `/assets/object/${object.directory}/${object.filename}`;
          this.name = object.name;
          this.quantity = object.quantity;
          this.price = object.price;
          this.memberUsername = object.username;
          this.instances = object.instances;
        })
    } else {
    return await this.$http.get(`/object_instance/${ this.objectId }/properties/`)
      .then((response) => {
        let object = response.data.objectInstance[0];
        this.imgFile = `/assets/object/${object.directory}/${object.image}`;
        this.objectFile = `/assets/object/${object.directory}/${object.filename}`;
        this.ownerId = object.member_id;
        this.sessionId = this.$store.data.user.id;
        this.placeId = object.place_id;
        this.originalName = object.name;
        this.name = object.object_name;
        this.price = object.object_price;
        this.buyer = object.object_buyer;
        this.memberUsername = object.username;
        this.canModify = false;
        if(this.sessionId === this.ownerId){
          this.canModify = true;
        }
        if(this.price === null){
          this.price = '';
        }
        if(this.buyer === null){
          this.buyer = '';
        }
      });
     }
  },
  reload(){
    window.location.reload();
  },
  close(){
    window.close();
  },
  async changeDetails() {
    this.name = (<HTMLInputElement>document.getElementById('objectName')).value.replace(/[^0-9a-zA-Z \-\[\]\/()]/g, '');
    this.price = (<HTMLInputElement>document.getElementById('objectPrice')).value.replace(/[^0-9]/g, '');
    const badwords = require("badwords-list");
    const bannedwords = badwords.regex;
    if (this.name.match(bannedwords)) {
      alert('You can not use this type of language on CTR!');
      this.name = this.originalName;
    }
    if(this.name === ""){
      this.name = this.originalName;
    }
    if(this.price === ""){
      this.price = null;
    }
    this.buyer = (<HTMLInputElement>document.getElementById('objectBuyer')).value;
    if(this.buyer === ""){
      this.buyer = null;
    }
    await this.update();
  },
  loadObjectPreview() {
    const browser = X3D.createBrowser();
    document.querySelector("#objectModel").appendChild(browser);
    const objectURL = '/assets/object/ObjectPreview.wrl';
    const objectViewer = X3D.getBrowser();
    objectViewer.loadURL(new X3D.MFString(objectURL));
    setTimeout(this.loadObject, 3000);
  },
  loadObject(){
    console.log('adding object');
    const browser = X3D.getBrowser();
    const inline = browser.currentScene.createNode("Inline");
    inline.url = new X3D.MFString(this.objectFile);
    browser.currentScene.addRootNode(inline);
  },
  async update() {
    this.success = 'Object updated';
    setTimeout(this.emitUpdate, 300);
    await this.$http.post(`/object_instance/update/`, {
      id: this.objectId,
      name: this.name,
      price: this.price,
      buyer: this.buyer,
    });
  },
  changeACtive(){
    switch (this.active) {
      case "properties":
        this.active = "model";
        setTimeout(this.loadObjectPreview, 100);
        break;
    }
  },
  startSocketListeners(){
    this.$socket.on("update-object", object => {
      if(object.obj_id === this.objectId){
        this.objectProperties();
      }
    });
  },
  async buy(){
    if(!this.mallObject){
      if(this.walletBalance >= this.price){
        try{
            await this.objectProperties();
            if(!this.price){
              throw new Error('This object is not for sale!')
            }
            if(this.buyer && this.$store.data.user.username !== this.buyer) {
              throw new Error('This object is reserved for someone else!');
            }
            if(this.price > this.walletBalance){
              throw new Error("You don't have enough cc's.");
            }
            await this.$http.post(`/object_instance/buy/`, {
            id: this.objectId});
            this.emitUpdate();
            await this.objectProperties();
            this.success = 'Object purchased!';
            this.error = '';
          } catch(e) {
            this.success = '';
            this.error = "Purchase failed to process.";
            console.log("Purchase Unsuccessful");
          }
      } else {
        throw new Error("You don't have enough cc's.");
      }
    } else {
      if(this.walletBalance >= this.price) {
        try{
          await this.$http.post(`/mall/buy/`, {
            id: this.objectId});
          this.success = 'Object purchased!';
          await this.objectProperties();
        } catch(e) {
          console.log("Purchase Unsuccessful");
        }
      }
    }
  },
  emitUpdate(){
    this.$socket.emit('update-object', {
      obj_id: this.objectId,
      place_id: this.placeId,
      member_username: this.memberUsername,
      buyer_username: this.$store.data.user.username,
    });
  },
},
created(){
  this.objectProperties();
},
mounted() {
  this.startSocketListeners();
},
});

