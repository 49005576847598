













































































import Vue from "vue";

export default Vue.extend({
  name: "UserSearch",
  data: () => {
    return {
      totalCount: 0,
      users: [],
      search: "",
      limit: 10,
      offset: 0,
      showNext: true,
      error: null,
    };
  },
  props: [
    "accessLevel",
  ],
  methods: {
    async getUsers(): Promise<any> {
      try {
        return this.$http.get(
          "/admin/usersearch/", {
            limit: this.limit,
            offset: this.offset,
            search: this.search,
          },
        ).then((response) => {
          this.users = response.data.results.users;
          this.totalCount = response.data.results.total[0].count;
        });
      } catch (error) {
        this.error = error;
      }
    },
    async searchUsers(): Promise<any> {
      this.offset = 0;
      try {
        return this.$http.get(
          "/admin/usersearch/", {
            limit: this.limit,
            offset: this.offset,
            search: this.search,
          },
        ).then((response) => {
          this.users = response.data.results.users;
          this.totalCount = response.data.results.total[0].count;
        });
      } catch (error) {
        this.error = error;
      }
    },
    async next() {
      this.offset = this.offset + this.limit;
      await this.getUsers();
    },
    async back() {
      this.offset = this.offset - this.limit;
      await this.getUsers();
      this.showNext = true;
    },
  },
  async created() {
    await this.getUsers();
  },
});
