















import Vue from "vue";

import ModalService from './services/ModalService.vue';
import Modal from './Modal.vue';

export default Vue.extend({
  components: { Modal },
  data() {
    return {
      modal: {},
    }
  },
  created() {
    ModalService.$on('open', ({ component, props, resolve, reject }) => {
      this.modal = {
        component,
        props,
        close: value => {
          this.modal = {};
          resolve(value);
        },
        dismiss: reason => {
          this.modal = {};
          reject(reason);
        },
      };
    });
  }
});
