





















import Vue from "vue";

export default Vue.extend({
  name: "UserSubMenu",
  computed: {
    isUserDetailRoute() {
      return this.$route.name === "UserView" || this.$route.name === "UserEdit";
    },
    isUserBanRoute() {
      return this.$route.name === "UserBanHistory" || this.$route.name === "UserBanAdd";
    },
  },
});
