






































































































































import Vue from "vue";
import { colonyDataHelper, homeDataHelper } from "@/helpers";

export default Vue.extend({
  name: "BlockMovePage",
  props: [
    "block",
    "hood",
    "colony",
  ],
  data: () => {
    return {
      loaded: false,
      showError: false,
      error: "",
      homeResponse: undefined,
      relocating: null,
      complete: false,
      locations: [],
      homeData: homeDataHelper,
      colonyData: colonyDataHelper,
      houseName: "",
      houseDescription: "",
      firstName: "",
      lastName: "",
      icon2d: null,
      home3d: null,
      donorLevel: undefined,
    };
  },
  methods: {
    getData(): Promise<void> {
      return Promise.all([
        this.$http.get("/block/" + this.$route.params.id + "/locations"),
        this.$http.get("/home"),
        this.$http.get("/member/getdonorlevel"),
      ]).then((response) => {
        this.locations = response[0].data.locations;
        this.homeResponse = response[1].data;
        this.donorLevel = response[2].data.name;
        console.log(this.donorLevel);

        if(this.donorLevel === "Champion"){
          this.homeData.championhome.price = 0;
        }

        if(this.homeResponse.homeData) {
          this.relocating = true;
        } else {
          this.relocating = false;
        }

        document.title = "Move - Cybertown";
        this.loaded = true;
      });
    },
    async settle() {
      this.showError = false;
      this.error = "";

      try {
        await this.$http.post("/home/settle", {
          blockId: this.$route.params.id,
          location: this.$route.params.location,
          houseName: this.houseName,
          houseDescription: this.houseDescription,
          firstName: this.firstName,
          lastName: this.lastName,
          icon2d: this.icon2d,
          home3d: this.home3d,
        });

        this.$store.data.user.hasHome = true;
        this.complete = true;

      } catch(e) {
        this.error = e.response.data.error;
        this.showError = true;
      }
    },
    async relocate() {
      this.showError = false;
      this.error = "";

      try {
        await this.$http.post("/home/move", {
          blockId: this.$route.params.id,
          location: this.$route.params.location,
        });

        this.complete = true;

      } catch(e) {
        this.error = e.response.data.error;
        this.showError = true;
      }
    },
  },
  mounted() {
    this.getData();

  },
});
