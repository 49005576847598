var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{staticClass:"h-full w-full bg-black flex flex-col"},[_c('div',{staticClass:"w-full flex-1 text-center"},[_c('div',{staticClass:"inline-block mx-auto"},[_c('div',{staticClass:"grid grid-cols-6 gap-0",style:({
					padding: '16px 19px 13px 10px',
					width: '540px',
					height: '300px',
					'background-image': _vm.mapBackground
				})},_vm._l((30),function(index){return _c('div',{key:index,staticStyle:{"height":"53px"}},[(_vm.blocks.find(function (b) { return b.location === index; }))?[_c('router-link',{staticClass:"w-full h-full block text-center flex items-center justify-center",style:({
								'background-image': _vm.blockBackground
							}),attrs:{"to":'/block/' +
									_vm.blocks.find(function (b) { return b.location === index; })
										.id}},[_c('span',[_vm._v(_vm._s(_vm.blocks.find(function (b) { return b.location === index; }).name))])])]:_vm._e()],2)}),0)]),_c('br'),_c('small',[_vm._v("Click a block on the Neighborhood map above to go to the homes")])]),_c('div',{staticClass:"flex flex-none h-1/3 bg-chat"},[(_vm.loaded)?_c('chat',{ref:"chat",attrs:{"place":_vm.hood}}):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }