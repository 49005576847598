









































































import Vue from "vue";

export default Vue.extend({
  name: "AvatarSearch",
  data: () => {
    return {
      totalCount: 0,
      avatars: [],
      status: 2,
      limit: 10,
      offset: 0,
      showNext: true,
      error: null,
      statusLabel: [
        'Deleted',
        'Live',
        'Pending Approval',
      ],
      accessLabel: [
        'Public',
        'Private'
      ]
    };
  },
  props: [
    "accessLevel",
  ],
  methods: {
    async getResults(): Promise<any> {
      try {
        return this.$http.get(
          "/admin/avatars/", {
          limit: this.limit,
          offset: this.offset,
          status: this.status,
        },
        ).then((response) => {
          this.avatars = response.data.results.avatars;
          this.totalCount = response.data.results.total[0].count;
        });
      } catch (error) {
        this.error = error;
      }
    },

    async approve(id): Promise<any> {
      try {
        return this.$http.post(
          "/admin/avatars/approve", {
          id: id,
        },
        ).then((response) => {
          this.getResults();
        });
      } catch (error) {
        this.error = error;
      }
    },
    async reject(id): Promise<any> {
      try {
        return this.$http.post(
          "/admin/avatars/reject", {
          id: id,
        },
        ).then((response) => {
          this.getResults();
        });
      } catch (error) {
        this.error = error;
      }
    },

    downloadWrl(id) {
      const avatar = this.avatars.find(av => av.id === id);
      window.location.assign(`/assets/avatars/${avatar.directory}/${avatar.filename}`);
    },
    downloadThumbnail(id) {
      const avatar = this.avatars.find(av => av.id === id);
      window.location.assign(`/assets/avatars/${avatar.directory}/${avatar.image}`);
    },
    async next() {
      this.offset = this.offset + this.limit;
      await this.getResults();
    },
    async back() {
      this.offset = this.offset - this.limit;
      await this.getResults();
      this.showNext = true;
    },
  },
  async created() {
    await this.getResults();
  },
});
