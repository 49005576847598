



























import Vue from 'vue';

export default Vue.extend({
  name: "HomeTools",
  data: () => {
    return {
      loaded: false,
    };
  },
  methods: {
    async opener(link) {
      window.open(link, "targetWindow", "height=650,width=800,menubar=no,status=no");
    },
  },
  mounted() {
    this.loaded = true;
  },
});
