





























































































import Vue from "vue";

import Modal from './Modal.vue';
import ModalMixin from './mixins/ModalMixin';
import InfoModal from "./InfoModal.vue";
import ModalService from "./services/ModalService.vue";
import StorageEditModal from './StorageEditModal.vue';

export default Vue.extend({
  name: "StorageModal",
  components: {Modal},
  data: () => ({
      username: undefined,
      unitName: null,
      unitId: null,
      units: [],
      objects: [],
      storageObjects: [],
      backpack: [],
      moveToBackpack: [],
      moveToStorage: [],
      page: 'storage',
      showError: false,
      error: "",
      showSuccess: false,
  }),
  methods: {
    openInfoModal(): void {
      ModalService.open(InfoModal);
    },
    openEditModal(): void {
      ModalService.open(StorageEditModal);
    },
    async openStorageUnit(id, name) {
      this.unitName = name;
      this.unitId = id;
      this.page = 'unit';
      this.getStorageObjects();
      this.getBackpackObjects();
    },
    async getStorageObjects(){
      this.moveToBackpack = [];
      this.moveToStorage = [];
      this.storageObjects = []
      const storage = await this.$http.get(`/place/${this.unitId}/object_instance`);
      storage.data.object_instance.forEach(obj => {
        this.storageObjects.push(obj);
      })
    },
    async getBackpackObjects(){
      this.moveToBackpack = [];
      this.moveToStorage = [];
      this.backpack = [];
      const response = await this.$http.get(`/member/backpack/${this.username}`);
      this.backpack = response.data.objects;
    },
    backToStorage(){
      this.page = 'storage';
    },
    async moveObjectsToBackpack(){
      try{
        if(this.moveToBackpack.length >= 1){
          await this.$http.post('/object_instance/backpack', {
            id: this.moveToBackpack
          });
          this.getUnits();
          this.getStorageObjects();
          this.getBackpackObjects();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async moveObjectsToStorage(){
      try{
        if(this.moveToStorage.length >= 1){
          await this.$http.post('/object_instance/storage', {
            id: this.moveToStorage,
            place_id: this.unitId
          });
          this.getUnits();
          this.getStorageObjects();
          this.getBackpackObjects();
        }
      } catch (error) {
        console.log(error);
      }
    },
    moveAllBackpack(){
      if(confirm("This will remove all the items in this storage unit and place them in your backpack.\n\nDo you want to continue?")){
        this.storageObjects.forEach(obj => {
          this.moveToBackpack.push(obj.id);
        })
        this.moveObjectsToBackpack();
      }
    },
    moveAllStorage(){
      if(confirm("This will remove all the items from your backpack and place them in this storage unit.\n\nDo you want to continue?")){
        this.backpack.forEach(obj => {
          this.moveToStorage.push(obj.id);
        })
        this.moveObjectsToStorage();
      }
    },
    async getUnits(){
      this.username = this.$store.data.user.username;
      this.units = [];
      try{
        const storageUnits = await this.$http.get(`/member/storage`);
        storageUnits.data.storage.forEach(unit => {
          this.units.push(unit);
        });
      } catch (errorResponse: any) {
        console.error(errorResponse);
      }
    },
    objectOpener(id) {  
      window.open("/#/object/"+id, "targetWindow", "width=1000px,height=700px,location=0,menubar=0,status=0,scrollbars=0");
    },
    startSocketListeners(){
    this.$socket.on("update-object", (event) => {
      if(event.place_id === this.unitId){
        setTimeout(this.getStorageObjects, 100);
      }
    });
  },
  },
  mounted() {
    this.startSocketListeners();
    this.getUnits();
  },
  mixins: [ModalMixin],
});
