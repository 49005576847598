





















































































































import Vue from "vue";

import WorldBrowserPage from "./pages/world-browser/WorldBrowserPage.vue";
import ModalRoot from "./components/modals/ModalRoot.vue";
import InfoModal from "./components/modals/InfoModal.vue";
import ModalService from "./components/modals/services/ModalService.vue";
import ClockPage from "./components/Clock.vue";


declare const X3D: any;

export default Vue.extend({
  name: "App",
  components: {
    ClockPage,
    WorldBrowserPage,
    ModalRoot,
  },
  data: () => {
    return {
      jumpGateData: [
        {
          title: "COLONIES:",
          slug: "",
        },
        {
          title: "Sci-fi",
          slug: "scifi_col",
        },
        {
          title: "Entertainment",
          slug: "ent_col",
        },
        {
          title: "Games",
          slug: "games_col",
        },
        {
          title: "Virtual Worlds",
          slug: "vrtwrlds_col",
        },
        {
          title: "Cyberhood",
          slug: "cyberhood",
        },
        {
          title: "Inner Realms",
          slug: "inrlms_col",
        },
        {
          title: "The Campus",
          slug: "campus",
        },
        {
          title: "Adventure",
          slug: "ad_col",
        },
        {
          title: "Hi-Tek",
          slug: "hitek_col",
        },
        {
          title: "9th Dimension",
          slug: "9thdimension",
        },
        {
          title: "-----------------------",
          slug: "",
        },
        {
          title: "The Plaza",
          slug: "enter",
        },
        {
          title: "Employment Office",
          slug: "employment",
        },
        {
          title: "Flea Market",
          slug: "fleamarket",
        },
        {
          title: "Mall",
          slug: "mall",
        },
        {
          title: "Bank",
          slug: "bank",
        },
        {
          title: "Sunset Beach",
          slug: "beach",
        },
        {
          title: "Water Park",
          slug: "waterpark",
        },
        {
          title: "Theme Park",
          slug: "themepark",
        },
        {
          title: "City Hall",
          slug: "cityhall",
        },
        {
          title: "Performing Arts",
          slug: "theatre",
        },
        {
          title: "The Pool",
          slug: "pool",
        },
        {
          title: "The Stadium",
          slug: "stadium",
        },
        {
          title: "The Post Office",
          slug: "postoffice",
        },
        {
          title: "Game Show",
          slug: "gameshow",
        },
        {
          title: "Black Market",
          slug: "blackmarket",
        },
        {
          title: "Jail",
          slug: "jail",
        },
        {
          title: "Fun Park",
          slug: "funpark",
        },
        {
          title: "Theatre",
          slug: "theatre",
        },
        {
          title: "(more coming soon)",
          slug: "",
        },
        /* For the curious developers. These worlds need fixing to work (see dev tools console)
               
                {
                    'title': 'Employment Office',
                    "slug": "employment"
                },
                {
                    'title': 'Outlands',
                    "slug": "outlands"
                },
                {
                    'title': 'Le Cafe',
                    "slug": "cafe"
                },
                {
                    'title': 'Library (missing wrl)',
                    "slug": "library"
                },
                {
                    'title': 'Fun Park',
                    "slug": "funpark"
                },
                {
                    'title': 'Fun Park',
                    "slug": "funpark"
                },

                 */
      ],
      jumpGate: "",
    };
  },
  methods: {
    changeJumpGate(): void {
      if (this.jumpGate?.length) {
        this.$router.push({ path: `/place/${this.jumpGate}` });
        this.jumpGate = "";
      }
    },
    openInfoModal(): void {
      ModalService.open(InfoModal);
    },
  },
  mounted() {
    //todo populate jumpgate with worlds
    X3D(
      () => {
        console.log("starting X3d");
        this.$store.data.x3dReady = true;
      },
      (error) => {
        console.error(error);
      },
    );
    require("./libs/x_ite_mods/spec_color.js");
    require("./libs/x_ite_mods/relax_route.js");
    require("./libs/x_ite_mods/relax_is.js");
    require("./libs/x_ite_mods/arrow_keys.js");
    require("./libs/x_ite_mods/viewpoint_bind.js");
    require("./libs/x_ite_mods/allow_sf_string.js");
    //require('./libs/x_ite_mods/speed_multiplier.js');
    require("./libs/x_ite_mods/bxx_speed_avatar.js");
    require("./libs/x_ite_mods/default_gravity.js");
    require("./libs/x_ite_mods/extend_context_menu.js");
    require("./libs/x_ite_mods/bxx_auth.js");
    //require('./libs/x_ite_mods/fix_stairs.js');
  },
  computed: {

  },
});
