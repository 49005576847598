







































































import Vue from "vue";
  
import Modal from "./Modal.vue";
import ModalMixin from "./mixins/ModalMixin";
  
import InfoModal from "./InfoModal.vue";
import ModalService from "./services/ModalService.vue";

export default Vue.extend({
  name: "PersonalInfoModal",
  components: {Modal},
  async created() {
    const { data } = await this.$http.get("/member/info");
    this.info = data.memberInfo;
    this.selectedRoleId = this.info.primary_role_id;
    await this.$http.get("/member/roles").then((response) => {
      this.roles = response.data.roles;
    });
  },
  data: () => {
    return {
      error: undefined,
      info: {
        username: undefined,
        email: undefined,
	firstName: undefined,
	lastName: undefined,
        immigrationDate: undefined,
        walletBalance: undefined,
        xp: undefined,
	chatdefault: undefined,
        primary_role_id: undefined,
      },
      roles: [],
      selectedRoleId: null,
      success: undefined,
    };
  },
  methods: {
    backToInfoModal(): void {
      ModalService.open(InfoModal);
    },
    update(): void {
      try {
        this.$http.post("/member/update_role", {
          primaryRoleId: this.selectedRoleId,
        });
        this.$http.post("/member/updateinfo", {
	  firstName: this.info.firstName,
	  lastName: this.info.lastName,
	  chatdefault: this.info.chatdefault,
        });
        this.error = null;
        this.success = "Information Updated";
      }catch (error) {
        this.success = null;
        this.error = error;
      }
    },
  },
  mixins: [ModalMixin],
});
