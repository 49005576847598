

















































































































import Vue from "vue";
import {colonyDataHelper, homeDataHelper} from "@/helpers";

export default Vue.extend({
  name: "HomeUpdateHomePage",
  data: () => {
    return {
      loaded: false,
      showError: false,
      error: "",
      complete: false,
      hasHome: false,
      homeData: homeDataHelper,
      colonyData: colonyDataHelper,
      home: undefined,
      colony: undefined,
      icon2d: null,
      home3d: null,
      homeName: "",
      donorLevel: undefined,
    };
  },
  methods: {
    async getHome() {
      try {
        const homeResponse = await this.$http.get("/home");
        await this.$http.get("/member/getdonorlevel")
          .then((response) => {
            this.donorLevel = response.data.name;
            console.log(this.donorLevel);
          });

        if(this.donorLevel === "Champion"){
          this.homeData.championhome.price = 0;
        }

        this.hasHome = !!homeResponse.data.homeData;
        if(this.hasHome) {
          this.home = homeResponse.data.homeData;
          if(homeResponse.data.homeDesignData) {
            this.home3d = homeResponse.data.homeDesignData.id;
          }
          this.icon2d = this.home.map_icon_index;
          this.homeName = this.home.name;
          const blockResponse  = await this.$http.get(`/block/${  homeResponse.data.blockData.id}`);
          this.colony = blockResponse.data.colony;
        }
        this.loaded = true;

      } catch(e) {
        console.error(e);
      }

    },

    async update() {
      this.showError = false;
      this.error = "";

      try {
        await this.$http.post("/home/update", {
          homeName: this.homeName,
          icon2d: this.icon2d,
          home3d: this.home3d,
        });

        this.complete = true;

      } catch(e) {
        this.error = e.response.data.error;
        this.showError = true;
      }

    },
  },
  mounted() {
    this.getHome();
  },
});
