
















































































import Vue from "vue";

export default Vue.extend({
  name: "PlaceSearch",
  data: () => {
    return {
      totalCount: 0,
      places: [],
      type: 'public',
      limit: 10,
      offset: 0,
      showNext: true,
      error: null,
      status: ['Disabled','Active'],
    };
  },
  props: [
    "accessLevel",
  ],
  methods: {
    async getResults(): Promise<any> {
      try {
        return this.$http.get(
          "/admin/places", {
          limit: this.limit,
          offset: this.offset,
          type: this.type,
        },
        ).then((response) => {
          this.places = response.data.results.places;
          if(this.type === 'home' || this.type === 'club'){
            this.places.forEach (place => {
              this.$http.get(`/member/info/${place.member_id}`)
              .then(result => {
                place.username = result.data.memberInfo.username;
                this.places.splice(place, this.places.indexOf(place.id));
              });
            })
          }
          if(this.type !== 'block'){
            if(this.type === 'shop'){
              this.places.forEach (place => {
                this.$http.get(`/mall/objects/${place.id}`)
                .then(result => {
                  place.objects = result.data.objects.length;
                  this.places.splice(place, this.places.indexOf[place.id]);
                })
              })
            } else {
              this.places.forEach (place => {
                this.$http.get(`/place/${place.id}/object_instance`)
                .then(result => {
                  place.objects = result.data.object_instance.length;
                  this.places.splice(place, this.places.indexOf[place.id]);
                })
              })
            }
          }
          this.totalCount = response.data.results.total[0].count;
        });
      } catch (error) {
        this.error = error;
      }
    },
    async updateStatus(id, state){
      let updateStatus;
      if(state === 0){
        updateStatus = 1;
      } else {
        updateStatus = 0;
      }
      try{
        await this.$http.post("/admin/places/update/", {
          id: id,
          column: 'status',
          content: updateStatus
        }).then(response => {
          if(response.data.status === 'success'){
            this.getResults();
          }
        })
      } catch(error){
        console.log(error);
      }
    },
    async updateName(id, name){
      let newName = prompt("Current Name:\n " + name + "\n\nNew Name:", name);
      if(newName !== null && newName !==''){
        try{
        await this.$http.post("/admin/places/update/", {
          id: id,
          column: 'name',
          content: newName
        }).then(response => {
          if(response.data.status === 'success'){
            this.getResults();
          }
        })
      } catch(error){
        console.log(error);
      }
      }
    },
    async updateDesc(id, desc){
      let newDesc = prompt("Current Description:\n " + desc + "\n\nNew Description:", desc);
      if(newDesc !== null && newDesc !==''){
        try{
        await this.$http.post("/admin/places/update/", {
          id: id,
          column: 'description',
          content: newDesc
        }).then(response => {
          if(response.data.status === 'success'){
            this.getResults();
          }
        })
      } catch(error){
        console.log(error);
      }
      }
    },
    async next() {
      this.offset = this.offset + this.limit;
      await this.getResults();
    },
    async back() {
      this.offset = this.offset - this.limit;
      await this.getResults();
      this.showNext = true;
    },
  },
  async created() {
    await this.getResults();
  },
});
