












import Vue from "vue";

export default Vue.extend({
  name: "admin",
  data: () => {
    return {
      accessLevel: "none",
    };
  },
  methods: {
    async getAdminLevel(): Promise<void> {
      try{
        await this.$http.get("/member/getadminlevel")
          .then((response) => {
            this.accessLevel = response.data.accessLevel;
            if (this.accessLevel === "none"){
              this.$router.push({name: "restrictedaccess"});
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {
    this.getAdminLevel();
  },
});
