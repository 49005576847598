












































import Vue from "vue";

import { colonyDataHelper } from '@/helpers';

export default Vue.extend({
  name: "BlockMapPage",
  props: [
    "block",
    "hood",
    "colony",
  ],
  data: () => {
    return {
      loaded: false,
      locations: [],
    };
  },
  methods: {
    getData(): void {
      this.$http.get("/block/" + this.$route.params.id + "/locations")
      .then((response) => {
        this.locations = response.data.locations;
        document.title = this.block.name + " - Cybertown";
        this.loaded = true;
      });

    },
    mapIconImage (index): string {
      return "/assets/img/map_themes/" + colonyDataHelper[this.colony.slug].map_theme +
        "/block/Picon2D"+(index-1).toString().padStart(3,"0")+".gif";
    },
  },
  computed: {
    mapBackground(): string {
      return "url('/assets/img/map_themes/" + colonyDataHelper[this.colony.slug].map_theme +
        "/block/Pimg2D000.gif')";
    },
    freeImage(): string {
      return "/assets/img/map_themes/" + colonyDataHelper[this.colony.slug].map_theme +
        "/block/Ficon2D000.gif";
    },
  },
  mounted() {
    this.getData();
  },
});
