





















import Vue from "vue";
import {response} from "express";

export default Vue.extend({
  name: "InformationPage",
  data: () => {
    return {
      owner: null,
      deputies: [],
    };
  },
  methods: {
    async getData(): Promise<void> {
      let infopoint = null;
      console.log(this.$route.params.type);
      switch (this.$route.params.type) {
      case "block":
        infopoint = `/block/${
          this.$route.params.id
        }/getAccessInfo/`;
        break;
      case "hood":
        infopoint = `/hood/${
          this.$route.params.id
        }/getAccessInfo/`;
        break;
      case "colony":
        infopoint = `/colony/${
          this.$route.params.id
        }/getAccessInfo/`;
        break;
      default:
        break;
      }
      return this.$http.get(infopoint).then((response) => {
        this.owner = response.data.data.owner[0].username;
        response.data.data.deputies.forEach((username, index) => {
          this.deputies[index] = username;
        });
      });
    },
    async opener(link): Promise<void> {
      window.opener.location.href = link;
      window.close();
    },
  },
  mounted() {
    this.getData();
  },
});
