


































































































































import Vue from "vue";

import { debugMsg } from "@/helpers";
import {response} from "express";

export default Vue.extend({
  name: "Inbox",
  data: () => {
    return {
      active: "view",
      boardadmin: false,
      body: "",
      ddate: "",
      dfrom: "",
      dfromid: 0,
      did: 0,
      display: false,
      dmessage: "",
      dparentid: "",
      dreply: "",
      dsubject: "",
      error: "",
      intro: "",
      inboxmessages: [],
      placeinfo: [],
      subject: "",
      success: "",
    };
  },
  methods: {
    //manage introduction information for message board
    async changeInboxIntro(): Promise<void> {
      try {
        await this.$http.post("/inbox/changeinboxintro", {
          place_id: this.$route.params.place_id,
          intro: this.intro,
          type: this.placeinfo[0].type,
        });
        this.success = "Inbox Information Updated";
        this.error = "";
        this.display = false;
      } catch (error) {
        this.error = error.response.data.error;
        this.success = "";
      } finally {
        this.getInboxMessages();
        this.getInfo();
        this.active = "view";
      }

    },
    //delete a specific message
    async deleteInboxMessage(): Promise<void> {
      try {
        const {data} = await this.$http.post("/inbox/deletemessage/", {
          place_id: this.$route.params.place_id,
          message_id: this.did,
          type: this.placeinfo[0].type,
        });
        this.success = "Message Deleted";
        this.error = "";
        this.display = false;
      } catch (error) {
        this.error = error.response.data.error;
        this.success = "";
      } finally {
        this.getInboxMessages();
      }

    },
    //get admin info from db and/or check if user is owner of message board
    async getAdminInfo(): Promise<any> {
      console.log(this.placeinfo[0].type);
      return this.$http.post("/inbox/getadmininfo", {
        place_id: this.$route.params.place_id,
        type: this.placeinfo[0].type,
      }).then((response) => {
        this.boardadmin = response.data.admin;
        if (!this.boardadmin){
          this.active = "post";
        }
      });
    },

    //get message board introduction information
    async getInfo(): Promise<void> {
      return this.$http.post("/inbox/info/", {
        place_id: this.$route.params.place_id,
      }).then((response) => {
        this.placeinfo = response.data.placeinfo;
      });
    },
    //get and prepares specific information for displaying in lower div
    async getMessage(
      id: number,
      date: string,
      user: string,
      subject: string,
      parentid: string,
      reply: string,
    ): Promise<void> {
      return this.$http.post("/inbox/getmessage/", {
        message_id: id,
		place_id: this.$route.params.place_id,
		type: this.placeinfo[0].type,
      }).then((response) => {
        console.log(response.data);
        this.dmessage = response.data.message;
        this.ddate = date;
        this.dfrom = user;
        this.dfromid = response.data.member_id;
        this.dsubject = subject;
        this.did = id;
        this.dparentid = parentid;
        this.dreply = reply;
        this.display = true;
      });
    },
    //gets all messages that are active on message board
    async getInboxMessages(): Promise<void> {
      return this.$http.post("/inbox/messages/", {
        place_id: this.$route.params.place_id,
      }).then((response) => {
        this.inboxmessages = response.data.inboxmessages;
      });
    },
    //post a message to the message board
    async postInboxMessage(): Promise<void> {
      try {
        const {data} = await this.$http.post("/inbox/postmessage", {
          place_id: this.$route.params.place_id,
          subject: this.subject,
          body: this.body,
        });
        this.success = "Message was posted";
        this.error = "";
        if (this.boardadmin) this.active = "view";
        else this.active = "post";
        this.subject = "";
        this.body = "";
        this.getInboxMessages();
      } catch (error) {
        this.error = error.response.data.error;
        //this.body = error.response.data.error.body;
        this.success = "";
      }
    },
    //post a reply to message board
    async postInboxReply(): Promise<void> {
      try {
        const {data} = await this.$http.post("/inbox/postreply", {
          memberId: this.dfromid,
          subject: this.dsubject,
          body: this.body,
          parent_id: this.dparentid,
        });
        this.success = "Reply was sent";
        this.error = "";
        this.active = "view";
        this.subject = "";
        this.body = "";
      } catch (error) {
        console.log(error);
        this.error = error.response.data.error;
        this.success = "";
      }
    },
    //button action for manage
    switchManage(): void {
      this.intro = this.placeinfo[0].inbox_intro;
      this.active = "manage";
    },
    //button action for post
    switchPost(): void {
      this.active = "post";
    },
    //button action for reply
    switchReply(): void {
      this.active = "reply";
    },
    //button action for message view
    switchView(): void {
      this.body = "";
      this.subject = "";
      this.display = false;
      this.error = "";
      this.success = "";
      if (this.boardadmin){
        this.active = "view";
      } else window.close();
    },
  },
  async created() {
    await this.getInfo();
    await this.getAdminInfo();
    if (this.boardadmin) await this.getInboxMessages();
  },
  watch: {
    active: function(newValue) {
      if (newValue === "view") {
        this.getInboxMessages();
      }
    },
  },
});
