





















































































































































import Vue from 'vue';
import appStore from "@/appStore";

export default Vue.extend({
  name: "SignupPage",
  data() {
    return {
      email: "",
      email2: "",
      username: "",
      password: "",
      password2: "",
      showError: false,
      showSuccess: false,
      error: "",
    };
  },
  methods: {
    async signup() {
      this.showError = false;

      if (this.password !== this.password2) {
        this.error = "Please enter your password the same twice.";
        this.showError = true;
        return;
      }

      try {
        const { data } = await this.$http.post("/member/signup", {
          email: this.email,
          username: this.username,
          password: this.password,
        });
        this.showSuccess = true;

        this.$store.methods.setUser({
          username: data.username,
          hasHome: false,
        });

        this.$store.methods.setToken(data.token);
        this.$router.push({ path: "/place/enter" });
      } catch (error: any) {
        if (error.response.data.error) {
          this.error = error.response.data.error;
          this.showError = true;
        } else {
          this.error = "An unknown error occurred";
          this.showError = true;
        }
      }
    },
  },
});
