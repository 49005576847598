


























































































import Vue from 'vue';

export default Vue.extend({
  name: "ForgotPasswordPage",
  data: () => {
    return {
      email: "",
      showError: false,
      error: "",
      success: false,
    };
  },
  methods: {
    async reset() {
      this.showError = false;
      try {
        if (this.email === "") {
          this.error = "Please enter your email address for your account.";
          this.showError = true;
          return;
        }
        await this.$http.post("/member/send_password_reset", {
          email: this.email,
        });
        this.success = true;
      } catch (errorResponse: any) {
        if (errorResponse.response.data.error) {
          this.error = errorResponse.response.data.error;
          this.showError = true;
        } else {
          this.error = "An unknown error occurred";
          this.showError = true;
        }
      }
    },
  },
});
