















import Vue from "vue";

export default Vue.extend({
  name: "CreatorPage",
  data: () => {
    return {
      canAdmin: false,
      showError: false,
      error: '',
      success: '',
      showSuccess: false,
      loaded: false,
    };
  },
  methods: {
    close(){
      window.close();
    },
  },
	async mounted(): Promise<void> {
    this.loaded = true;
  },
});
