






































































































import Vue from 'vue';

export default Vue.extend({
  name: "ForgotPasswordPage",
  data: () => {
    return {
      newPassword: "",
      newPassword2: "",
      showError: false,
      error: "",
      success: false,
    };
  },
  methods: {
    async reset() {
      this.showError = false;
      try {
        if (
          this.newPassword === "" ||
          this.newPassword2 === "" ||
          this.newPassword !== this.newPassword2
        ) {
          this.error =
            "Please enter your new password in exactly the same twice.";
          this.showError = true;
          return;
        }

        await this.$http.post("/member/reset_password", {
          resetToken: this.$route.query.token,
          newPassword: this.newPassword,
          newPassword2: this.newPassword2,
        });

        this.success = true;
      } catch (errorResponse: any) {
        if (errorResponse.response.data.error) {
          this.error = errorResponse.response.data.error;
          this.showError = true;
        } else {
          this.error = "An unknown error occurred";
          this.showError = true;
        }
      }
    },
  },
});
