














































import Vue from "vue";

import Modal from './Modal.vue';
import ModalMixin from './mixins/ModalMixin';
import InfoModal from "./InfoModal.vue";
import AvatarUploadModal from "./AvatarUploadModal.vue";
import ModalService from "./services/ModalService.vue";

export default Vue.extend({
  name: "AvatarModal",
  components: {Modal},
  data: () => {
    return {
      avatarId: null,
      avatars: [],
      showError: false,
      showSuccess: false,
      error: "",
    };
  },
  methods: {
    openInfoModal(): void {
      ModalService.open(InfoModal);
    },
    openAvatarUploadModal(): void {
      ModalService.open(AvatarUploadModal);
    },
    async save(id) {
      try {
        const response = await this.$http.post("/member/update_avatar", {
          avatarId: id,
        });
        this.$store.data.user.avatar.id = id;
        this.$store.methods.setToken(response.data.token);
        this.avatarId = id;
        this.showSuccess = true;
      } catch (errorResponse: any) {
        if (errorResponse.response.data.error) {
          this.error = errorResponse.response.data.error;
          this.showError = true;
        } else {
          this.error = "An unknown error occurred";
          this.showError = true;
        }
      }
    },
  },
  mounted() {
    this.$http.get("/avatar")
      .then(response => {
        this.avatars = response.data.avatars.reverse();
      });

    this.avatarId = this.$store.data.user.avatar.id;
  },
  mixins: [ModalMixin],
});
