




































import Vue from "vue";

export default Vue.extend({
  name: "HomeUpdatePage",
  data: () => {
    return {
      loaded: false,
      hasHome: false,
      links: [
        {
          "img": "/assets/img/homes/updhome.jpg",
          "label": "Home",
          "link": "/home/update/home",
        },
        {
          "img": "/assets/img/homes/updinfo.jpg",
          "label": "Information",
          "link": "",
        },
        {
          "img": "/assets/img/homes/updimage.jpg",
          "label": "Image",
          "link": "",
        },
        {
          "img": "/assets/img/homes/updinfo.jpg",
          "label": "Reset",
          "link": "",
        },
        {
          "img": "/assets/img/homes/updright.jpg",
          "label": "Chat Access Rights",
          "link": "",
        },
        {
          "img": "/assets/img/homes/updpet.jpg",
          "label": "Configure Virtual Pet",
          "link": "",
        },
        {
          blank: true,
        },
        {
          "img": "/assets/img/homes/updright.jpg",
          "label": "Blocked From Chat",
          "link": "",
        },

      ],
    };
  },
  methods: {
    async getHome() {
      try {
        const homeResponse = await this.$http.get("/home");

        this.hasHome = !!homeResponse.data.homeData;
        this.loaded = true;

      } catch(e) {
        console.error(e);
      }

    },
  },
  mounted() {
    this.getHome();
  },
});
