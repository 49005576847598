






















































































































import Vue from "vue";
import {response} from "express";

export default Vue.extend({
  name: "AccessRightsPage",
  data: () => {
    return {
      data: [],
      loaded: false,
      access: false,
      owner: null,
      deputies: [
        {username: null},
        {username: null},
        {username: null},
        {username: null},
        {username: null},
        {username: null},
        {username: null},
        {username: null}],
      success: false,
    };
  },
  methods: {
    async hasAccess(): Promise<boolean> {
      let endpoint = null;
      switch (this.$store.data.place.type) {
      case "block":
        endpoint =
            `/block/${
              this.$store.data.place.id
            }/can_manage_access`;
        break;
      case "hood":
        endpoint =
            `/hood/${
              this.$store.data.place.id
            }/can_manage_access`;
        break;
      case "colony":
        endpoint =
            `/colony/${
              this.$store.data.place.id
            }/can_manage_access`;
        break;
      default:
        break;
      }

      try {
        await this.$http.get(endpoint);
      } catch (error) {
        this.access = false;
        this.loaded = true;
        return;
      }
    },
    async getData(): Promise<void> {
      let infopoint = null;
      switch (this.$store.data.place.type) {
      case "block":
        infopoint = `/block/${
          this.$store.data.place.id
        }/getAccessInfo/`;
        break;
      case "hood":
        infopoint = `/hood/${
          this.$store.data.place.id
        }/getAccessInfo/`;
        break;
      case "colony":
        infopoint = `/colony/${
          this.$store.data.place.id
        }/getAccessInfo/`;
        break;
      default:
        break;
      }
      this.loaded = true;
      return this.$http.get(infopoint).then((response) => {
        if (response.data.data.owner.length !== 0) {
          this.owner = response.data.data.owner[0].username;
        } else {
          this.owner = "";
        }
        response.data.data.deputies.forEach((username, index) => {
          this.deputies[index] = username;
        });
      });
    },
    async updateAccess(): Promise<void> {
      let updatepoint = null;
      switch (this.$store.data.place.type) {
      case "block":
        updatepoint = `/block/${
          this.$store.data.place.id
        }/postAccessInfo/`;
        break;
      case "hood":
        updatepoint = `/hood/${
          this.$store.data.place.id
        }/postAccessInfo/`;
        break;
      case "colony":
        updatepoint = `/colony/${
          this.$store.data.place.id
        }/postAccessInfo/`;
        break;
      default:
        break;
      }
      try {
        await this.$http.post(updatepoint, {deputies: this.deputies, owner: this.owner});
        this.success = true;
        this.getData();
      } catch (error) {
        this.access = false;
        this.getData();
      }
    },
  },
  async mounted(): Promise<void> {
    if (
      typeof this.$store.data.place.id === "undefined" ||
      typeof this.$store.data.place.type === "undefined"
    ) {
      console.error("Place is not set.");
      return;
    }

    try {
      if (!this.hasAccess()) {
        return;
      }
      this.access = true;
    } catch (e) {
      console.error(e);
    }
    this.getData();
  },
});
