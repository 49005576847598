






















































































































































































































































































































































































import Vue from 'vue';

export default Vue.extend({
  name: "Homepage",
  data: () => {
    return {};
  },
  methods: {
    MM_swapImage() {},
    MM_swapImgRestore() {},
  },
  mounted() {
    if (this.$store.data.isUser) {
      this.$router.push({ path: "/place/enter" });
    }
  },
});
