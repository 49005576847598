






















































































import Vue from "vue";
import { colonyDataHelper } from "@/helpers";

export default Vue.extend({
	name: "BlockWizardPage",
	props: ["block", "hood", "colony"],
	data: () => {
		return {
			loaded: false,
			locations: [],
			availableLocations: []
		};
	},
	methods: {
		async getData(): Promise<void> {
			this.$http
				.get("/block/" + this.$route.params.id + "/locations")
				.then(response => {
					this.locations = response.data.locations;
					this.availableLocations = this.locations
						.filter(location => {
							return location.available;
						})
						.map(loc => {
							return loc.location;
						});

					document.title = this.block.name + " Wizard - Cybertown";
					this.loaded = true;
				});
		},
		update(): void {
			this.$http
				.post("/block/" + this.$route.params.id + "/locations", {
					availableLocations: this.availableLocations
				})
				.then(() => {
					this.$router.push({path: `/block/${this.$store.data.place.block.id}`});
				});
		},
		async checkAdmin(): Promise<boolean> {
			try {
				const adminCheck = await this.$http.get(
					"/block/" + this.$store.data.place.block.id + "/can_admin"
				);
				return true;
			} catch (e) {
				return false;
			}
		}
	},
	computed: {
		mapBackground(): string {
			return (
				"url('/assets/img/map_themes/" +
				colonyDataHelper[this.colony.slug].map_theme +
				"/block/Pimg2D000.gif')"
			);
		}
	},
	async mounted(): Promise<void> {
		if (!(await this.checkAdmin())) {
			this.$router.push("/restricted");
		} else {
			this.getData();
		}
	},
	async beforeDestroy() {}
});
