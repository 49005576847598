var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',[_c('div',{staticClass:"w-full flex-1 text-center"},[_c('div',{staticClass:"inline-block mx-auto"},[_c('div',{staticClass:"grid grid-cols-12 gap-0",style:({
					width: '480px',
					height: '240px',
					'background-image': _vm.mapBackground
				})},_vm._l((72),function(index){return _c('div',{key:index,staticStyle:{"height":"40px","line-height":"40px"}},[(_vm.locations.find(function (b) { return b.location === index; }))?[(
								_vm.locations.find(function (b) { return b.location === index; }).id
							)?_c('router-link',{staticClass:"w-full h-full block text-center flex items-center justify-center",attrs:{"to":'/block/' +
									_vm.locations.find(
										function (b) { return b.location === index; }
									).id}},[_c('span',{staticStyle:{"padding":"3px","max-height":"40px","line-height":"13px","overflow":"hidden"}},[_vm._v(_vm._s(_vm.locations.find(function (b) { return b.location === index; }) .name))])]):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.availableLocations),expression:"availableLocations"}],attrs:{"type":"checkbox"},domProps:{"value":index,"checked":Array.isArray(_vm.availableLocations)?_vm._i(_vm.availableLocations,index)>-1:(_vm.availableLocations)},on:{"change":function($event){var $$a=_vm.availableLocations,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=index,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.availableLocations=$$a.concat([$$v]))}else{$$i>-1&&(_vm.availableLocations=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.availableLocations=$$c}}}})]:[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.availableLocations),expression:"availableLocations"}],attrs:{"type":"checkbox"},domProps:{"value":index,"checked":Array.isArray(_vm.availableLocations)?_vm._i(_vm.availableLocations,index)>-1:(_vm.availableLocations)},on:{"change":function($event){var $$a=_vm.availableLocations,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=index,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.availableLocations=$$a.concat([$$v]))}else{$$i>-1&&(_vm.availableLocations=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.availableLocations=$$c}}}})]],2)}),0)]),_c('p',[_c('strong',[_vm._v("Update Wizard for block '"+_vm._s(this.$store.data.place.block.name)+"'")])]),_c('small',[_vm._v("Checkmark the plots where you want members to settle down.")]),_c('br'),_c('button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":_vm.update}},[_vm._v("Update")]),_c('br'),_vm._m(0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('small',[_vm._v(" Change the "),_c('a',{attrs:{"href":"block<$g_exe>?ac=wizardimage&ID=<$ID>","target":"place"}},[_vm._v("background image")]),_vm._v(" for this "),_c('strong',[_vm._v("block")]),_vm._v(". ")])}]

export { render, staticRenderFns }