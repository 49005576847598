





































































































import Vue from "vue";

export default Vue.extend({
  name: "UserChat",
  data: () => {
    return {
      chat: [],
      totalCount: 0,
      search: "",
      limit: 10,
      messageId: null,
      offset: 0,
      showNext: true,
      showDelete: false,
      error: null,
    };
  },
  methods: {
    async getUserChat(): Promise<any> {
      try {
        return this.$http.get("/admin/userchat", {
          search: this.search,
          user: this.$route.params.id,
          limit: this.limit,
          offset: this.offset,
        }).then((response) => {
          this.chat = response.data.results.messages;
          console.log(this.chat);
          this.totalCount = response.data.results.total[0].count;
        });
      } catch (error) {
        this.error = error;
      }
    },
    async searchPlace(): Promise<any> {
      this.offset = 0;
      try {
        return this.$http.get("/admin/userchat", {
          search: this.search,
          user: this.$route.params.id,
          limit: this.limit,
          offset: this.offset,
        }).then((response) => {
          this.chat = response.data.results.messages;
          this.totalCount = response.data.results.total[0].count;
        });
      } catch (error) {
        this.error = error;
      }
    },
    async next() {
      this.offset = this.offset + this.limit;
      await this.getUserChat();
    },
    async back() {
      this.offset = this.offset - this.limit;
      await this.getUserChat();
      this.showNext = true;
    },
    async deletemessage(id: number): Promise<void> {
      try{
        this.$http.post(`/message/message/${id}`);
      } catch (e) {
        console.log(e);
      }
      this.showDelete = false;
      this.getUserChat();
    },
  },
  async created() {
    await this.getUserChat();
  },
});
