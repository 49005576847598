




























































































































































































































import Vue from "vue";

export default Vue.extend({
  name: "MallUploadPage",
  data: () => {
    return {
      showError: false,
      showForm: true,
      error: '',
      showSuccess: false,
      loaded: false,
      name: '',
      price: 10,
      quantity: 10,
      wrlFile: {},
      imageFile: {},
      textureFile: {},
    };
  },
  methods: {
    async upload(): Promise<void> {
      this.showError = false;
      this.showSuccess = false;

      try {
        await this.$http.post("/object/add", {
          name: this.name,
          price: this.price,
          quantity: this.quantity,
          wrlFile: this.wrlFile,
          textureFile: this.textureFile,
          imageFile: this.imageFile,
        }, true);
        this.showSuccess = true;
        this.showForm = false;
      } catch (errorResponse: any) {
        if (errorResponse.response.data.error) {
          this.error = errorResponse.response.data.error;
          this.showError = true;
        } else {
          this.error = "An unknown error occurred";
          this.showError = true;
        }
      }
    },
    setFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      this[e.target.dataset.id] = files[0];

    },
    reload(){
      window.location.reload();
    },
    close(){
      window.close();
    },
  },
  mounted() {
    this.loaded = true;
  },
  watch: {
  },
});
