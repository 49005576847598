


































































import Vue from "vue";

export default Vue.extend({
  name: "UserBanAdd",
  data() {
    return {
      banDuration: 3,
      banType: "jail",
      banReason: "",
      success: "",
      error: "",
    };
  },
  methods:{
    async addBan(): Promise <void>{
      try {
        await this.$http.post("/admin/ban", {
          ban_member_id: this.$route.params.id,
          time_frame: this.banDuration,
          type: this.banType,
          reason: this.banReason,
        })
          .then(() => {
            this.success = "Ban Added";
          });
      } catch (e) {
        this.error = e.response.data.message;
      }
    },
  },
});
